import React,{ useRef } from "react";
import emailjs from "@emailjs/browser"
import "./Join.css";

export const Join  = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_20sqv3j', 'template_vtko7ms', form.current, '9dZaW1fM82jVkhG8-')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };


    return(
        <div className="Join" id="join-us">
            <div className="left-j">
                <hr />
               <div>
                <span className="stroke-text">READY TO</span>
                <span>LEVEL UP</span>
               </div>
               <div>
                <span>YOUR BODY</span>
                <span className="stroke-text">WITH US?</span>
               </div>
            </div>
            <div className="right-j">
                <form ref={form} onSubmit={sendEmail} className="email-container">
                    <input type="email" name="user-email" 
                    placeholder="Enter Your Email address" id="" />
                    <button className="btn btn-j">Join Now</button>
                </form>
            </div>
        </div>
    )
}